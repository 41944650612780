<div [class.collapsed]="sideNav.isCollapsed" class="sidenav">
	<div class="sidenav-top">
		<a routerLink="/">
			<img src="assets/Dixon-logo-website.png" alt="dixon logo" height="38" />
		</a>
		<a routerLink="/" id="small-logo">
			<img src="assets/dixon-logo-small.png" alt="dixon logo" height="38" />
		</a>
		<button (click)="sideNav.toggleCollapsed()" mat-icon-button>
			<mat-icon>chevron_left</mat-icon>
		</button>
	</div>
	<ul>
		<ng-container *ngIf="isInternal">
			<li>
				<a routerLink="clients" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
					<mat-icon>checklist</mat-icon>
					<span class="collapsible">Clients</span>
				</a>
			</li>
			<li *ngIf="isAdmin">
				<a routerLink="users" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
					<mat-icon>group</mat-icon>
					<span class="collapsible">Internal Users</span>
				</a>
			</li>
			<hr />
		</ng-container>
		<ng-container *ngIf="clients$ | async as otherClients">
			<mat-form-field appearance="outline" class="px-3 d-flex collapsible">
				<mat-label>Client</mat-label>
				<mat-select (selectionChange)="onClientChange($event.value)" [value]="(activeClient$ | async)?.id">
					<mat-option *ngFor="let client of otherClients" [value]="client.id">
						{{ client.name ? (client.state ? client.name + ', ' + client.state : client.name) : client.id }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container>
		<ng-container *ngIf="activeClient$ | async as activeClient">
			<li>
				<a routerLink="{{ AppRoute.Clients }}/{{ activeClient.id }}/{{ AppRoute.LprReport }}" routerLinkActive="active">
					<mat-icon>monitoring</mat-icon>
					<div class="collapsible">Rapid LPR Tool</div>
				</a>
			</li>
			<li *ngIf="isAdmin">
				<a routerLink="{{ AppRoute.Clients }}/{{ activeClient.id }}/{{ AppRoute.Users }}" routerLinkActive="active">
					<mat-icon>group</mat-icon>
					<div class="collapsible">Users</div>
				</a>
			</li>
		</ng-container>
	</ul>
</div>
